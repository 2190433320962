import { GlobalShoeBoxProps } from "components/dist/organisms/GlobalShoeBox";
import { ShoeboxItemResponseDto } from "src/backend";
import { getUserDisplayName } from "src/utils/user/get-user-display-name";

const mapShoeBoxItemResponseToFile = (item: ShoeboxItemResponseDto): GlobalShoeBoxProps['folders'][0]['files'][0] => {
    const file: GlobalShoeBoxProps['folders'][0]['files'][0] = {
        id: item.id,
        title: item.title,
        documentName: item.document.name,
        uploading: false,
        documentId: item.document.id,
        uploadProgress: 0,
        ownerId: item.shoeboxOwner.id,
        uploadedById: item.uploadedBy.id,
        loanId: item.loanRole?.loan,
        shoeboxType: item.shoeboxType
    }

    return file;
}

const reduceItemsToFolders = (items: ShoeboxItemResponseDto[],
    usersLoansMap: Record<string, GlobalShoeBoxProps['folders'][0]['loans']>,
    loggedInUserId: string,
) => {
    return items.reduce((all, next) => {
        const file: GlobalShoeBoxProps['folders'][0]['files'][0] = mapShoeBoxItemResponseToFile(next)

        const messages = [
            ...all[next.uploadedBy?.id]?.messages ?? []
        ]
        const nextMessage = next.document.message?.message;
        const files = [
            ...all[next.uploadedBy?.id]?.files ?? [],
            file
        ]
        const fullName = getUserDisplayName({ givenName: next.uploadedBy?.givenName, familyName: next.uploadedBy?.familyName });
        const folder: GlobalShoeBoxProps['folders'][0] = {
            key: next.uploadedBy?.id,
            name: next.uploadedBy?.emailAddress,
            ownerUserId: next.shoeboxOwner.id,
            senderUserId: next.uploadedBy?.id,
            isSubFolder: true,
            subFolders: [],
            senderName: fullName ?? next.uploadedBy?.emailAddress,
            senderGivenName: next.uploadedBy?.givenName,
            senderEmailAddress: fullName ? next.uploadedBy?.emailAddress : "",
            owner: next.uploadedBy?.id === loggedInUserId ? "ME" : "OTHER",
            loans: usersLoansMap[next.uploadedBy?.id] ?? [],
            files,
            filesCount: files.length,
            messages: !!nextMessage && messages.findIndex((message) => message === nextMessage) === -1 ? [
                ...messages,
                nextMessage
            ] : messages
        };

        return {
            ...all,
            [next.uploadedBy?.id]: folder
        }
    }, {});
}

export const mapShoeboxItemsToFolder = (
    items: ShoeboxItemResponseDto[],
    defaultFolder: GlobalShoeBoxProps['folders'][0],
    usersLoansMap: Record<string, GlobalShoeBoxProps['folders'][0]['loans']>,
    teamShoeboxItems: Record<string, ShoeboxItemResponseDto[]>,
    loggedInUserId: string = defaultFolder.senderUserId
): Record<string, GlobalShoeBoxProps['folders'][0]> => {
    if (!items || !Array.isArray(items) || items.length === 0 && !teamShoeboxItems) {
        return {
            [defaultFolder.senderUserId]: defaultFolder
        }
    }

    const myItems = items.filter((item) => item.uploadedBy?.id === loggedInUserId);
    const otherItems = items.filter((item) => item.uploadedBy?.id !== loggedInUserId);

    const myFiles = myItems
        .map(mapShoeBoxItemResponseToFile);

    const subFolders = reduceItemsToFolders(
        otherItems,
        usersLoansMap,
        loggedInUserId
    )

    const myShoebox: GlobalShoeBoxProps['folders'][0] = {
        ...defaultFolder,
        filesCount: items.length,
        files: myFiles,
        subFolders: Object.values(subFolders)
    }

    const teamShoeBoxes = Object.entries(teamShoeboxItems ?? {})
        .filter(([userId]) => userId !== loggedInUserId)
        .reduce((all, [userId, items]) => {
            const shoeboxOwner = items[0].shoeboxOwner;
            const shoeboxOwnerFiles = items
                .filter((item) => item.uploadedBy?.id === shoeboxOwner.id)
                .map(mapShoeBoxItemResponseToFile);

            const otherItems = items
                .filter((item) => item.uploadedBy?.id !== shoeboxOwner.id);

            const subFolders = reduceItemsToFolders(
                otherItems,
                usersLoansMap,
                loggedInUserId
            )

            const folder = {
                key: `parent-${shoeboxOwner.id}`,
                name: shoeboxOwner.emailAddress,
                ownerUserId: shoeboxOwner.id,
                senderUserId: shoeboxOwner.id,
                isSubFolder: false,
                subFolders: Object.values(subFolders),
                filesCount: items.length,
                senderName: `${getUserDisplayName(shoeboxOwner)} ShoeBox`,
                senderGivenName: shoeboxOwner.givenName,
                senderEmailAddress: shoeboxOwner.emailAddress,
                owner: "OTHER",
                loans: usersLoansMap[shoeboxOwner.id] ?? [],
                files: shoeboxOwnerFiles,
                messages: [],
            }

            return {
                ...all,
                [userId]: folder
            }
        }, {});

    return {
        [defaultFolder.senderUserId]: myShoebox,
        ...teamShoeBoxes
    };
}